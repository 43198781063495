import theme from 'theme/theme';
import { Address } from 'types/Address';

export enum PixChargeDiscountType {
	FIXED_VALUE_UNTIL_THE_DATES_INFORMED = 'FIXED_VALUE_UNTIL_THE_DATES_INFORMED',
	PERCENTAGE_DATE_REPORTED = 'PERCENTAGE_DATE_REPORTED',
}

export enum PixChargeInterestType {
	PERCENTAGE_PER_MONTH_CALENDAR_DAYS = 'PERCENTAGE_PER_MONTH_CALENDAR_DAYS',
}

export enum PixChargeDebtorType {
	PERSON = 'PERSON',
	COMPANY = 'COMPANY',
}

export enum PixChargeFineType {
	FIXED_VALUE = 'FIXED_VALUE',
	PERCENT = 'PERCENT',
}

type DiscountPix = {
	date: string;
	amount: number;
};

export interface IPix {
	discount_type?: PixChargeDiscountType;
	discount_dates_settings?: {
		values: DiscountPix[];
		type: PixChargeDiscountType;
	};
	amount: string;
	due_date: string;
	id: string;
	pix_key: string;
	status: EPix;
	pre_signed_url?: string;
	interest_amount?: number;
	interest_type?: PixChargeInterestType;
	fine_amount?: number;
	fine_type?: PixChargeFineType;
	updated_at?: string;
	emv?: string;
	url?: string;
	debtor: {
		taxpayer_id: string;
		name: string;
		email_address?: string;
		address: Address;
	};
}

export type TPixFilters = {
	end_date?: string;
	start_date?: string;
	status?: EPix;
	taxpayer_id?: string;
};

export type TCreatePixPayload = {
	clientRequestId?: string;
	description?: string;
	amount: number;
	dueDate: string;
	expirationAfterPayment: number;
	debtor: {
		type: PixChargeDebtorType;
		name: string;
		emailAddress?: string;
		taxpayerId: string;
		address?: Address;
	};
	fineAmount?: number;
	fineType?: PixChargeFineType;
	discountAmount?: number;
	discountType?: PixChargeDiscountType;
	discountDatesSettings?: DiscountPix[];
	interestAmount: number;
	interestType: PixChargeInterestType;
};

export enum EPix {
	EXPIRED = 'EXPIRED',
	ACTIVE = 'ACTIVE',
	PAID = 'PAID',
}

export const pixStatusDescription = {
	[EPix.EXPIRED]: 'Cancelado',
	[EPix.PAID]: 'Pago',
	[EPix.ACTIVE]: 'Pendente',
};

export const pixStatusDescriptionDetails = {
	[EPix.EXPIRED]: 'Sem pagamento',
	[EPix.PAID]: 'Pagamento realizado',
	[EPix.ACTIVE]: 'Sem pagamento',
};

export const pixDisplay = {
	[EPix.EXPIRED]: {
		label: pixStatusDescription[EPix.EXPIRED],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[EPix.PAID]: {
		label: pixStatusDescription[EPix.PAID],
		background: theme.successBackground,
		textColor: theme.success,
		statusColor: theme.successLight,
	},
	[EPix.ACTIVE]: {
		label: pixStatusDescription[EPix.ACTIVE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
};

export const pixDisplayDetails = {
	[EPix.EXPIRED]: {
		label: pixStatusDescriptionDetails[EPix.EXPIRED],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[EPix.PAID]: {
		label: pixStatusDescriptionDetails[EPix.PAID],
		background: theme.successBackground,
		textColor: theme.success,
		statusColor: theme.successLight,
	},
	[EPix.ACTIVE]: {
		label: pixStatusDescriptionDetails[EPix.ACTIVE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
};
