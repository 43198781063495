import {
	IBalanceBlockPayload,
	IBalanceManagement,
	IBalanceManagementListParams,
	IBalanceUnblockPayload,
} from 'modules/management/pages/BalanceManagement/types';
import { NetworkApi } from 'network';
import {
	Account,
	AccountActivationProps,
	AccountContent,
	AccountDto,
	AccountFilters,
	AccountOpeningDocumentsDto,
	AccountRelatedPartDetailed,
	AccountStatementFilters,
	AccountStatementMovement,
	AccountUpdateDto,
	IStatementExportPayload,
	IStatementExportResponse,
	IUpdateAccountStatusPayload,
	StatementStatusResponse,
} from 'types/Account';
import { List } from 'types/List';
import { IPix, TCreatePixPayload, TPixFilters } from '../pages/Pix/types';

export class AccountsService extends NetworkApi {
	public static getAccounts(filters?: AccountFilters) {
		return this.get<List<AccountContent>, AccountFilters>(
			'/accounts',
			filters,
		).catch(() => ({
			content: [],
			has_next: false,
			total_elements: 0,
			total_pages: 0,
		}));
	}

	public static getAccount(accountId: string) {
		return this.get<Account>(`/accounts/${accountId}`);
	}

	public static createAccount(account: AccountDto) {
		return this.post<{ id: string }, AccountDto>('/accounts', account);
	}

	public static updateAccount(accountId: string, account: AccountUpdateDto) {
		return this.patch<void, AccountUpdateDto>(
			`/accounts/${accountId}`,
			account,
		);
	}

	public static updateAccountStatus(
		accountId: string,
		payload: IUpdateAccountStatusPayload,
	) {
		return this.patch<void, IUpdateAccountStatusPayload>(
			`/accounts/${accountId}/status`,
			payload,
		);
	}

	public static updateOpeningDocuments({
		accountId,
		agreementKey,
	}: AccountActivationProps) {
		return this.put<void, AccountOpeningDocumentsDto>(
			`/accounts/${accountId}/send-agreement`,
			{
				documents: {
					agreement: agreementKey,
				},
			},
		);
	}

	/* Quando status com erro: BAAS_ACCOUNT_CREATION_ERROR  */
	public static retryCreateAccount(accountId: string) {
		return this.post<void, void>(
			`/accounts/${accountId}/retry-create`,
			undefined,
		);
	}

	/* Quando status com erro Clicksign: SIGNATURE_ERROR  */
	public static retryAgreementAccount(accountId: string) {
		return this.post<void, void>(
			`/accounts/${accountId}/agreement/retry-sending`,
			undefined,
		);
	}

	public static blockAccount(accountId: string) {
		return this.put<void, void>(`/accounts/${accountId}/block`, undefined);
	}

	public static unblockAccount(accountId: string) {
		return this.put<void, void>(
			`/accounts/${accountId}/unblock`,
			undefined,
		);
	}

	public static cancelAccount(accountId: string) {
		return this.put<void, void>(`/accounts/${accountId}/cancel`, undefined);
	}

	public static getBalance(accountId: string) {
		return this.get<{ amount: number; blocked_amount: number }, void>(
			`/accounts/${accountId}/balance`,
		);
	}

	public static getStatement(
		accountId: string,
		params: AccountStatementFilters,
	) {
		return this.get<
			List<AccountStatementMovement>,
			AccountStatementFilters
		>(`/accounts/${accountId}/statement`, {
			date_from: params.date_from,
			date_to: params.date_to,
			page: params.page,
			size: params.size,
		});
	}

	public static getStatementStatus(accountId: string, clientCode: string) {
		return this.get<StatementStatusResponse, void>(
			`/accounts/${accountId}/statement/${clientCode}/status`,
		);
	}

	public static getStatementExport(
		accountId: string,
		statementsList: IStatementExportPayload,
	) {
		return this.post<IStatementExportResponse, IStatementExportPayload>(
			`/accounts/${accountId}/statement/export`,
			statementsList,
		);
	}

	public static getBalanceRetention(
		accountId: string,
		params: IBalanceManagementListParams,
	) {
		return this.get<IBalanceManagement, IBalanceManagementListParams>(
			`/accounts/${accountId}/balance-retentions`,
			{
				page: params.page,
				order: params.order,
				size: params.size,
				sort: params.sort,
			},
		);
	}

	public static createBalanceRetention(
		accountId: string,
		payload: IBalanceBlockPayload,
	) {
		return this.post<void, IBalanceBlockPayload>(
			`/accounts/${accountId}/balance-retentions`,
			payload,
		);
	}

	public static deleteBalanceRetention(
		accountId: string,
		balanceId: string,
		payload: IBalanceUnblockPayload,
	) {
		return this.delete<void, IBalanceUnblockPayload>(
			`/accounts/${accountId}/balance-retentions/${balanceId}`,
			payload,
		);
	}

	public static upsertAccountParty(
		accountId: string,
		accountPartyData: AccountRelatedPartDetailed & { id: string },
	) {
		return this.put<void, AccountRelatedPartDetailed & { id: string }>(
			`/accounts/${accountId}/account-parties/${accountPartyData.id}`,
			accountPartyData,
		);
	}

	public static getPixCharge(accountId: string, pixId: string) {
		return this.get<IPix, void>(
			`/accounts/${accountId}/pix-charges/${pixId}`,
		);
	}

	public static getPixCharges(accountId: string, params?: TPixFilters) {
		return this.get<List<IPix>, TPixFilters>(
			`/accounts/${accountId}/pix-charges`,
			params,
		);
	}

	public static createPixCharge(
		accountId: string,
		payload: TCreatePixPayload,
	) {
		return this.post<void, TCreatePixPayload>(
			`/accounts/${accountId}/pix-charges`,
			payload,
		);
	}
}
