import styled from 'styled-components';

export const StyledWrapperChip = styled.div<{
	color: string;
	justify: string;
	square: boolean;
	shadow: boolean;
}>`
	background-color: ${({ color }) => color};
	border-radius: ${({ square }) => (square ? '7px' : '100px')};
	box-shadow: 0px 4px 10px 0px
		${({ shadow, color }) => (shadow ? color : 'none')};
	display: flex;
	justify-content: ${({ justify }) => justify};
	align-items: center;
	padding: 3px;
	max-width: 14rem;
	min-width: 10rem;

	p {
		margin-left: 0.5rem;
	}
`;

export const StyledStatusColor = styled.div<{ color: string }>`
	background-color: ${({ color }) => color};
	box-shadow: 0px 3px 10px 0px ${({ color }) => color};
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 100px;
`;

export const StyledStatusWrapper = styled.div`
	width: 1rem;
	height: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0.5rem;
`;
